<template>
	<div id="page-wrapper">
    <Header/>
    <Intro/>
    <Main/>
    <About/>
    <Footer/>
  </div>
  <Script/>
</template>

<script>
import Header from './components/PageHeader.vue'
import Intro from './components/TopIntro.vue'
import Main from './components/TopMain.vue'
import About from './components/TopAbout.vue'
import Footer from './components/PageFooter.vue'
import Script from './components/PageScript.vue'

export default {
  name: 'App',
  components: {
    Header,
    Intro,
    Main,
    About,
    Footer,
    Script
  }
}
</script>

<style>
#app {
  margin: 0;
}
</style>
