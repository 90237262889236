<template>
    <!-- Footer -->
    <section id="footer" class="wrapper">
        <div class="title" id="contact">Contact</div>
        <div class="container">
            <div class="col-6 col-12-medium">
                <!-- Contact -->
                    <section class="feature-list">
                        <div class="row aln-center">
                            <div class="col-6 col-12-small">
                                <section>
                                    <h3 class="icon solid fa-home">Address</h3>
                                    <p>
                                        栃木県宇都宮市下戸祭1-3-11 904
                                    </p>
                                </section>
                            </div>
                            <div class="col-6 col-12-small">
                                <section>
                                    <h3 class="icon solid fa-comment">Social</h3>
                                    <p>
                                        <a href="https://twitter.com/hijamac" target="_twitter">twitter</a><br />
                                        <a href="https://www.facebook.com/hijamac" target="_facebook">Facebook</a><br />
                                        <a href="https://www.instagram.com/hijamac/" target="_instagram">Instagram</a>
                                    </p>
                                </section>
                            </div>
                            <div class="col-6 col-12-small">
                                <section>
                                    <h3 class="icon solid fa-envelope">Email</h3>
                                    <p>
                                        <a href="mailto:contact@ash.qa">contact@ash.qa</a>
                                    </p>
                                </section>
                            </div>
                            <div class="col-6 col-12-small">
                                <section>
                                    <h3 class="icon solid fa-phone">Phone</h3>
                                    <p>
                                        (070) 8403-8582
                                    </p>
                                </section>
                            </div>
                        </div>
                    </section>

            </div>
        </div>
        <div id="copyright">
            <ul>
                <li>&copy; Advanced System House Co.</li>
            </ul>
        </div>
    </section>
</template>
