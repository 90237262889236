<template>
    <!-- Intro -->
    <section id="intro" class="wrapper style1">
        <div id="introduction" class="title">Introduction</div>
        <div class="container">
            <p class="style1">
                株式会社 Advanced System House はWEBサービス開発における バックエンドエンジニアリング<sup>※</sup>の知見と経験を強みとしています。
            </p>
            <p class="style1">
                これまでさまざまなWEBサービスの開発を手掛けた技術者が貴社のWEB制作現場に技術力と経験値を提供します。<br>
            </p>
            <p class="style2">
                ※バックエンドエンジニアリングとは、WEBサイトやサービスにおいて主にサーバー上で動作するプログラムの開発やサーバー構築・運用などに関わる技術です。<br>
            </p>
        </div>
    </section>
</template>