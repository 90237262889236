<template>
    <!-- Main -->
    <section id="main" class="wrapper style2">
        <div id="service" class="title">Service</div>
        <div class="container">
            <!-- Features -->
                <section id="features">
                    <header class="style1">
                        <p>要件定義から運用保守まで、バックエンドエンジニアリングに関わる行程を多様な技術で対応することが可能です。<br>
                            Web制作現場に対してのコンサルティングや技術的なサポートなども行っています。</p>
                    </header>
                    <div class="feature-list">
                        <div class="row">
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon solid fa-wrench">Program Development</h3>
                                    <h4>プログラム開発</h4>
                                    <p>SaaSやAPIなど、Webサービスのプログラム開発に幅広く対応いたします。</p>
                                </section>
                            </div>
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon solid fa-cog">Infrastructure Environment Building</h3>
                                    <h4>インフラ環境構築</h4>
                                    <p>自社サーバーやクラウドを問わず、貴社のWebサービスに最適なインフラ環境を構築します。</p>
                                </section>
                            </div>
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon solid fa-ambulance">Synthetic Monitoring</h3>
                                    <h4>サーバー監視サービス</h4>
                                    <p>常時Webサイトが正常に公開されているかを監視し、障害が発生した際には迅速に復旧対応いたします。</p>
                                </section>
                            </div>
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon solid fa-chart-line">Direction of Web Development</h3>
                                    <h4>WEB開発ディレクション</h4>
                                    <p>Webサービス開発に際し、最適なリソースとスケジュールを計画し、プロジェクトを完遂します。</p>
                                </section>
                            </div>
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon solid fa-sync">Analysis and Feedback</h3>
                                    <h4>Web制作環境コンサルティング</h4>
                                    <p>貴社のWeb制作や開発の環境について問題点の分析や業務改善案をご提案します。</p>
                                </section>
                            </div>
                            <div class="col-6 col-12-medium">
                                <section>
                                    <h3 class="icon fa-comment">Technical Solutions for Your Clients</h3>
                                    <h4>クライアント向け技術サポート</h4>
                                    <p>貴社のクライアントからの問い合わせや要望に対して、より信頼を高められるような適切な回答や提案を迅速に提供します。</p>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
        </div>
    </section>
</template>