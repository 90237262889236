<template>
    <!-- PageHeader -->
    <section id="pageheader" class="wrapper">

        <!-- Logo -->
            <div id="logo">
                <h1><a href="index.html">Advanced System House</a></h1>
                <p>アドバンスドシステムハウス</p>
            </div>

        <!-- Nav -->
            <nav id="nav">
                <ul>
                    <li class="current"><a href="index.html">Home</a></li>
                    <li><a href="#introduction">Introduction</a></li>
                    <li><a href="#service">Service</a></li>
                    <!-- li><a href="#works">Works</a></li -->
                    <li><a href="#about">About</a></li>
                    <li><a href="#contact">Contact</a></li>
                </ul>
            </nav>
    </section>
</template>
