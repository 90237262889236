<template>
    <div id="ext-script"></div>
  </template>
  
  <script>
  /* eslint-disable no-useless-escape */
  import postscribe from 'postscribe'
  export default {
    name: 'Gist-Example',
    mounted: function () {
      postscribe('#ext-script', 
    `<script src="/assets/js/jquery.min.js"><\/script>
    <script src="/assets/js/jquery.dropotron.min.js"><\/script>
    <script src="/assets/js/browser.min.js"><\/script>
    <script src="/assets/js/breakpoints.min.js"><\/script>
    <script src="/assets/js/util.js"><\/script>
    <script src="/assets/js/main.js"><\/script>`)
    },
  }
  </script>