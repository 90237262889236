<template>
    <!-- About -->
    <section id="about" class="wrapper style3">
        <div class="title" id="about">About</div>
        <div class="container">
            <header class="style1">
                <h2>会社概要</h2>
            </header>
            <div class="row aln-center">
                <div class="col-6 col-12-medium">
                    <section>
                        <h3>商号</h3>
                        <p>株式会社 Advanced System House</p>
                    </section>
                    <section>
                        <h3>創業</h3>
                        <p>2021年4月1日</p>
                    </section>
                    <section>
                        <h3>法人設立</h3>
                        <p>2023年1月11日</p>
                    </section>
                    <section>
                        <h3>所在地</h3>
                        <p>栃木県宇都宮市下戸祭1-3-11 904</p>
                    </section>
                    <section>
                        <h3>代表者</h3>
                        <p>代表取締役 桧山 聡</p>
                    </section>
                    <section>
                        <h3>事業内容</h3>
                        <p>システム開発<br>
                            WEBサイト制作<br>
                            サーバー運用保守<br>
                            ITコンサルティング<br>
                            ITサポート<br>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>
